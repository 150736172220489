import HTMLBeautifyComponent from "@jordibosch20/software_tools_package/dist/pages/HTMLBeautify.component.js"
import styles from "./App.module.css";
import { getHTMLBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/html-beautify";import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <HTMLBeautifyComponent title={"HTML beautify"} f={getHTMLBeautifyString}></HTMLBeautifyComponent>
      </div>
      <div className={styles.text}>
        <h3>Introduction</h3>
        <p>Dealing with HTML can often become difficult, especially when faced with minified code. Minification is common in the development process to reduce file size and improve loading times, but it's a double-edged sword when you're trying to debug or understand the structure of your HTML. A well-formatted HTML can make a world of difference in these situations.</p>
        <h3>Discover Our Solution: The Instant HTML Beautifier</h3>
        <p>Envision the ability to instantly transform any compact, minified HTML into a beautifully formatted and easy-to-read document with just a click. That's the magic our HTML beautifier brings to your fingertips. Forget about struggling through lines of squeezed-together code. Our tool untangles the complexity, making your HTML not only easier on the eyes but also optimized for better performance.</p>
        <h4>Key Features:</h4>
        <ul>
        <li><strong>One-Click Makeover:</strong> Paste your HTML, hit beautify, and behold &ndash; clear, accessible code.</li>
        <li><strong>Personalization:</strong> Adjust the output according to your coding preferences, from indentation levels to element alignment.</li>
        </ul>
        <h3>How to Use the Instant HTML Beautifier</h3>
        <ol>
        <li><strong>Copy and Paste:</strong> Begin with copying your condensed or cluttered HTML code into the beautifier's input field.</li>
        <li><strong>Adjust Preferences:</strong> Pick your preferred formatting options, such as the type of indentation.</li>
        <li><strong>Beautify:</strong> Click once to transform your code into an organized, optimized version.</li>
        <li><strong>Examine and Implement:</strong> Check the formatted code for any further tweaks needed, then easily integrate it back into your project.</li>
        </ol>
        <h3>Is It Safe to Paste Sensitive Code?</h3>
        <div>
        <div><span>Absolutely. The text you paste stays within your browser. Our tool operates entirely on the client side, eliminating any data transmission to servers. This ensures your information remains confidential and secure on your device.</span></div>
        </div>
        <p><strong>Can the beautifier handle extensive HTML files?</strong><br />A: Yes, indeed! Our tool is designed to efficiently process HTML files of any size, from small fragments to voluminous documents, ensuring quick and effective optimization. The bottleneck into how fast our tool can&nbsp;</p>
          <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;